import { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { PlaceOutlined, Verified, ChevronLeft } from '@mui/icons-material';
import { query, getDocs, collection, limit, where, orderBy, startAfter } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';
import { getAnalytics, logEvent } from 'firebase/analytics'
import { functions } from "../../../services/firebase/firebaseConfig";
import { db } from 'services/firebase/firebaseConfig';
import imgSuffix from 'common/image-suffix';
import constants from 'common/constants';
import colors from "theme/colors";
import { useNavigate, useParams } from 'react-router-dom';
import TiktokPixel from 'utils/tiktok_pixel';
import algoliasearch from 'algoliasearch';
import HorizontalCard from 'components/product-horizontal-card';

const client = algoliasearch(constants.algolia.appId, constants.algolia.searchKey)
const productsStores_index = client.initIndex('firebase_products_stores');

const StoreScreen = (props) => {
    const navigate = useNavigate()
    const params = useParams()
	const [loading, setLoading] = useState(true)
	const [storeId, setStoreId] = useState(null);
    const [username, setUsername] = useState(params.username || null)
	const [store, setStore] = useState(null);
    const [newProducts, setNewProducts] = useState([]);
    const [skipProducts, setSkipProducts] = useState(0);
    const [limitProducts, setLimitProducts] = useState(15);
    const [loadingMore, setLoadingMore] = useState(false);
    const [allProductsDisplayed, setAllProductsDisplayed] = useState(false);
    const [persist_scroll, set_persist_scroll] = useState(0);
    const [init, setInit] = useState(false)

	useEffect(() => {
        const analytics = getAnalytics();
		logEvent(analytics, 'screen_view', {
            firebase_screen: "TiendaOficial_Web", 
            firebase_screen_class: "TiendaOficial_Web"
		});
        TiktokPixel.pageView();
		if(username){
			getStore()
		}else{
			setLoading(false)
		}
	},[])

    useEffect(()=>{
        if(storeId){
            let storageItem = sessionStorage.getItem("store_products_persist")
            let products_persist = JSON.parse(storageItem)
            if(products_persist){
                set_persist_scroll(products_persist.scrollY)
                setSkipProducts(products_persist.skipProducts)
                setNewProducts(products_persist.products)
                setLoading(products_persist.loading)
                setLoadingMore(products_persist.loadingMore)
                setAllProductsDisplayed(products_persist.allProductsDisplayed)
                sessionStorage.removeItem("store_products_persist")
            }else{
                getNewProducts()
                updateClicks()
            }
        }
        setInit(true)
    },[storeId])

    useEffect(()=>{
        if(init == true){
            if(persist_scroll && newProducts.length > 0){
                window.scrollTo(0, persist_scroll)
                set_persist_scroll(0)
            }
        }
    },[newProducts, persist_scroll, init])

    const updateClicks = async () => {
        // try {
			const instance = httpsCallable(functions, 'stores-storeClick')
			instance({
				uid: storeId
			})
            .then((result) => {
                console.log("response: ", result)
            })
            .catch((error) => {
                console.log("error: ", error)
            });
		// } catch (error) {
		// 	console.log("error: ", error)
		// 	return false
		// }
    }

	const getStore = async () => {
		try {
            const colRefStores = collection(db, constants.mode + "stores")
            const docsRefStores = query(colRefStores, where("username", "==", username))
            const docSnapStore = await getDocs(docsRefStores);
            //console.log("DOCS: ", docSnapStore.size)
			if(!docSnapStore.empty){
                let s = docSnapStore.docs[0]
				let data = {
					...s.data(),
					uid: s.id
				}
				setStore(data);
                setStoreId(s.id)
			}else{
				setLoading(false)
			}
		} catch (error) {
			setLoading(false)
			console.log("Get store error: ", error)
		}
	}

    const getNewProducts = async () => {
        setLoadingMore(true)
        try {
            let filters = `store.label:"${store.name}"`
			productsStores_index.search("", {
				filters: filters,
                page: skipProducts,
				ruleContexts: "client"
			}).then(({ hits }) => {
				if(hits.length < 20){
                    setAllProductsDisplayed(true)
                }
                setSkipProducts(skipProducts + 1)
				let products = []
				hits.forEach((doc) => {
					products.push({
						...doc,
						uid: doc.objectID,
						price: parseFloat(doc.price),
                        uniqueCategory: doc.categories ? Object.keys(doc.categories)[0] : "",
					})
				});
				setNewProducts([...newProducts, ...products])
                setLoading(false)
                setLoadingMore(false)
			});
        } catch (error) {
            setLoading(false)
            setLoadingMore(false)
            console.log("Get products error: ", error)
        }
    }

    const moreProducts = async () => {
        if(loadingMore === false){
            //console.log("SKIP: ", skipProducts)
            getNewProducts()
        }
    }

    const openProduct = async (uid) => {
        let products_persist = {
            products: newProducts,
            skipProducts,
            loading,
            loadingMore,
            allProductsDisplayed,
            scrollY: window.scrollY,
            searchText: ""
        }
        sessionStorage.setItem("store_products_persist", JSON.stringify(products_persist))
        navigate(`/product/${uid}`)
        try{
            let click = {
                productId: uid,
                scopeType: `store_${storeId}`,
                origin: "organic_web_open"
            }
            const addProductClick = httpsCallable(functions, 'products-addProductClick')
            const result = await addProductClick(click);
            console.log(result.data.message);
        }catch(error){
            console.error('Error logging action:', error.message);
        }
    }

    const openBanner = async (filter) => {
        if(filter !== ""){
            navigate(`/store/${store.username}/products?category=${filter}`,{
                state:{
                    store,
                }
            })
        }
    }

    const openProductsList = async () => {
        // variable para los parametros
        var params = ""
        navigate(`/store/${store.username}/products?${params}`,{
            state:{
                store,
            }
        })
    }

    const returnCategory = (category) => {
        switch(category){
            case "agrochemicals":
                return "Agroquímicos"
            case "clothes_and_accessories":
                return "Ropa y accesorios"
            case "crops":
                return "Cultivos"
            case "energy": 
                return "Energía"
            case "fertilizers":
                return "Fertilizantes"
            case "infrastructure_and_terrain":
                return "Infraestructura y terrenos"
            case "livestock_inputs": 
                return "Insumos ganaderos"
            case "materials_and_tools":
                return "Materiales y herramientas"
            case "seeds":
                return "Semillas"
            case "services":
                return "Servicios"
            case "technology": 
                return "Tecnología"
            case "transport_and_machinery":
                return "Transporte y maquinaria"
            default:
                return ""
        }
    }

    const renderBanners = () => {
        let banners = store.banners
        if (store.storeMode === "type1") {
            return  <Grid container sx={{ display: "flex", flexDirection: "row", maxWidth: "600px", alignSelf: "center", marginTop: "8px", marginRight: "16px", backgroundColor: "white" }}>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "260px", height: "400px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner1.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner1.url + imgSuffix.md} 
                                    height={424}
                                    width={"100%"}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "260px", height: "188px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner2.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner2.url + imgSuffix.md} 
                                    height={200} 
                                    width={260}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "260px", height: "188px", marginTop: "24px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner3.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner3.url + imgSuffix.md} 
                                    height={200} 
                                    width={260}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
        } else if (store.storeMode === "type2") {
            return  <Grid container sx={{ display: "flex", flexDirection: "column", maxWidth: "600px", alignSelf: "center", marginTop: "8px", marginRight: "16px", backgroundColor: "white" }}>
                        <Grid item sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "100%", height: "200px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner1.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner1.url + imgSuffix.md} 
                                    height={200}
                                    width={"100%"}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Grid 
                                item 
                                xs={12} 
                                sm={5.75} 
                                sx={{ display: "flex", flexDirection: "column", width: "270px", height: "188px", borderRadius: "16px", overflow: "hidden", marginTop: "24px", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner2.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner2.url + imgSuffix.md} 
                                    height={188} 
                                    width={"100%"}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                            <Grid sx={0} sm={.5}/>
                            <Grid 
                                item 
                                xs={12} 
                                sm={5.75} 
                                sx={{ display: "flex", flexDirection: "column", width: "270px", height: "188px", borderRadius: "16px", overflow: "hidden", marginTop: "24px", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner3.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner3.url + imgSuffix.md} 
                                    height={188} 
                                    width={"100%"}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
        } else if (store.storeMode === "type3") {
            return  <Grid container sx={{ display: "flex", flexDirection: "column", maxWidth: "600px", alignSelf: "center", marginTop: "8px", marginRight: "16px", backgroundColor: "white" }}>
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "100%", height: "200px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner1.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner1.url + imgSuffix.md} 
                                    height={200}
                                    width={"100%"}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "100%", height: "188px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner2.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner2.url + imgSuffix.md} 
                                    height={200} 
                                    width={"100%"}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
        } else if (store.storeMode === "type4") {
            return  <Grid container sx={{ display: "flex", flexDirection: "row", maxWidth: "600px", alignSelf: "center", marginTop: "8px", marginRight: "16px", backgroundColor: "white" }}>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "260px", height: "400px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner1.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner1.url + imgSuffix.md} 
                                    height={424}
                                    width={276}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "260px", height: "400px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner2.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner2.url + imgSuffix.md} 
                                    height={424} 
                                    width={276}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
        } else if (store.storeMode === "type5") {
            return  <Grid container sx={{ display: "flex", flexDirection: "row", maxWidth: "600px", alignSelf: "center", marginTop: "8px", marginRight: "16px", backgroundColor: "white" }}>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "260px", height: "188px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner1.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner1.url + imgSuffix.md} 
                                    height={200} 
                                    width={276}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "260px", height: "188px", marginTop: "24px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner2.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner2.url + imgSuffix.md} 
                                    height={200} 
                                    width={276}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", marginTop: "24px", alignItems: "center" }}>
                            <Grid 
                                item 
                                sx={{ display: "flex", flexDirection: "column", width: "260px", height: "400px", borderRadius: "16px", overflow: "hidden", cursor: "pointer" }}
                                onClick={()=>openBanner(banners.banner3.filter)}
                            >
                                <img
                                    alt="img"
                                    src={banners.banner3.url + imgSuffix.md} 
                                    height={424}
                                    width={276}
                                    objectFit='cover'
                                    style={{ borderRadius: "16px" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
        }
    }

    const renderProductsList = () => {
        return  <Grid container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
                    {newProducts.map((product, index)=> {
                        if(newProducts){
                            return  <>
                                        <HorizontalCard
                                            product={product}
                                            openProduct={()=> openProduct(product.uid)}
                                            scopeType={`store_${storeId}`}
                                        />
                                        <div style={{ backgroundColor: colors.light_gray, height: "1px", width: "100%" }}/>
                                    </>
                        }else{
                            return  <Box>
                                        <Typography>Tienda no disponible</Typography>
                                    </Box>
                        }
                    })}
                    {loadingMore === true ? 
                        <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Typography>
                                Cargando...
                            </Typography>
                        </Box>
                    :
                        !allProductsDisplayed ?
                            <Button sx={{ marginTop: "8px" }} onClick={()=>moreProducts()}>
                                <Typography variant='title8' sx={{ color: colors.primary_green }}>Ver más...</Typography>
                            </Button>
                        :null
                    }
                </Grid>
    }

    const renderCategories = () => {
        if(Array.isArray(store.categories.lvl0)){
            return  <Grid container sx={{ display: "flex", flexDirection: "row", backgroundColor: "white", marginRight: "8px", marginTop: "8px" }}>
                        {store.categories.lvl0.map((category, index)=>{
                            return  <Grid item sx={{ display: "flex", flexDirection: "row", backgroundColor: "rgba(26, 166, 107, .1)", height: "28px", justifyContent: "center", alignItems: "center", borderRadius: "14px", marginRight: "8px", marginTop: "8px" }}>
                                        <Typography variant="body2" sx={{ paddingLeft: "16px", paddingRight: "16px", color: colors.primary_green }}>{returnCategory(category)}</Typography>
                                    </Grid>
                        })}
                    </Grid> 
        }else{
            return  <Grid item sx={{ display: "flex", flexDirection: "row", backgroundColor: "white", marginRight: "16px", marginTop: "16px" }}>
                        <Grid item sx={{ display: "flex", flexDirection: "row", backgroundColor: "rgba(26, 166, 107, .1)", height: "28px", justifyContent: "center", alignItems: "center", borderRadius: "14px" }}>
                            <Typography variant="body2" sx={{ paddingLeft: "16px", paddingRight: "16px", color: colors.primary_green }}>{returnCategory(store.categories.lvl0)}</Typography>
                        </Grid>
                    </Grid>
        }
    }

	try {
		if(loading){
			return <Container>
                        <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Typography>
                                Cargando...
                            </Typography>
                        </Box>
                    </Container>
		}
		return (
			store != null ? 
				<Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", paddingBottom: "40px" }}>
					<Grid container maxWidth="4" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", marginTop: "16px", backgroundColor: "white" }}>
                        <ChevronLeft sx={{ fontSize: 36, marginRight: "16px" }} color="primary" onClick={()=>{navigate(-1)}}/>
                        <Box sx={{ width: "100px", height: "100px", borderRadius: "50px", overflow: "hidden", backgroundColor: "#" + store.backgroundColor, alignSelf: "center" }}>
                            <img
                                alt="img"
                                src={store.logo + imgSuffix.md} 
                                height={100} 
                                width={100}
                                style={{ borderRadius: "50px", objectFit: "contain"}}
                            /> 
                        </Box>
                        <Grid item sx={{ display: "flex", flexDirection: "row", alignSelf: "center", justifyContent: "center", alignItems: "center", marginTop: "16px" }}>
                            <Typography variant="title6Bold" style={{ textAlign: "center" }}>{store.name}</Typography>
                            <Verified sx={{ fontSize: "18px", marginLeft: "4px", color: "#1fadc1" }}/>
                        </Grid>
                        <Grid item sx={{ display: "flex", flexDirection: "row", marginTop: "4px", alignSelf: "center" }}>
                            <PlaceOutlined fontSize="small" sx={{ color: colors.primary_green}}/>
                            <Typography variant="body2" sx={{ marginLeft: "4px", color: colors.primary_green }}>{store.location}</Typography>
                        </Grid>
                        <Typography variant="body2" sx={{ marginTop: "8px", textAlign: "left", marginRight: "16px", backgroundColor: "white" }}>{store.description}</Typography>
                        {store.categories && store.categories.lvl0 ? 
                            renderCategories()
                        : null}
                        {store.banners ? 
                            renderBanners()
                        : null}
                        {store.storeMode !== "" && store.banners && newProducts.length > 0 ?
                            <Grid container sx={{ display: "flex", flexDirection: "column", marginLeft: "0px", paddingRight: "0px", marginTop: "24px" }}>
                                <Grid item sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <Typography variant="title8">Productos nuevos</Typography>
                                    <Typography variant="body2" onClick={()=>openProductsList()} sx={{ color: colors.primary_green, cursor: "pointer" }}>Ver más {">"}</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', flexDirection: "row", overflow: "auto" }}>
                                    {newProducts.map((product, index)=>{
                                        if(product){
                                            return  <Grid item onClick={()=>openProduct(product.uid)} xs={{ display: "flex", flexDirection: "column", backgroundColor: "white", width: "144px", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                                        <Box item sx={{ display: "block", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "white", marginTop: "8px", cursor: "pointer", width: "128px", height: "128px", borderRadius: "8px", overflow: "hidden", position: "relative" }}>
                                                            {product.images ?
                                                                <img
                                                                    alt="img"
                                                                    src={product.images[0] + imgSuffix.md} 
                                                                    height={128} 
                                                                    width={128}
                                                                    style={{objectFit: "cover"}}
                                                                /> 
                                                            : (product.legacy_images ? 
                                                                <img
                                                                    alt="img"
                                                                    src={product.legacy_images[0] + "480x480"} 
                                                                    height={128} 
                                                                    width={128}
                                                                    style={{objectFit: "cover"}}
                                                                /> 
                                                            : "")}
                                                            {product.promotion != "" && product.promotion != null ?
                                                                <Box sx={{display: "flex", position: "absolute", bottom: "4px", right: "4px", backgroundColor: "sunset", marginLeft: "32px", height: "24px", borderRadius: "12px", paddingLeft: "8px", paddingRight: "8px",  alignItems: "center"}}>
                                                                    <Typography variant="body4" sx={{display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, color: "white" }}>{product.promotion}</Typography>
                                                                </Box>
                                                            :null}
                                                        </Box>
                                                        <Grid item sx={{ display: "flex", flexDirection: "column", marginTop: "4px", marginBottom: "8px", width: "144px", height: "110px", backgroundColor: "white", paddingLeft: "8px", paddingRight: "8px", justifyContent: "space-between", cursor: "pointer" }}>
                                                            <Grid item sx={{ display: "flex", flexDirection: "column" }}>
                                                                <Typography variant="caption1" sx={{ color: colors.primary_green }}>{product.categories && product.categories.lvl0 ? returnCategory(product.categories.lvl0).toUpperCase() : returnCategory(product.uniqueCategory).toUpperCase()}</Typography>
                                                                <Typography variant="title8" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>{product.name}</Typography>
                                                                <Typography variant="body2" sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{product.description}</Typography>
                                                            </Grid>
                                                            {product.price && product.price !== 0 && product.price !== "" && product.price !== null ?
                                                                <Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
                                                                    <Typography variant="title8">${product.price.toLocaleString("en-US")}&nbsp;</Typography>
                                                                    <Typography variant="caption1" sx={{ color: colors.medium_gray, paddingBottom: "2px" }}>{product.currency.toUpperCase()}</Typography>
                                                                </Grid> 
                                                            :null}
                                                        </Grid>
                                                    </Grid>
                                        }
                                    })}
                                </Grid>
                            </Grid>
                        :   newProducts.length > 0 ?                        
                                renderProductsList()
                        :null}
                    </Grid>
				</Container>
			:   <Container>
                    <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography>
                            Tienda no disponible
                        </Typography>
                    </Box>
                </Container>
		)
	} catch (error) {
		console.log("ERROR_PRODUCT_LOAD: ", error)
		return <Container>
                    <Box minHeight={250} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography>
                            Hubo un problema al cargar la tienda.
                        </Typography>
                    </Box>
                </Container>
	}
}

export default StoreScreen;
